<template>
    <v-container>
        <v-breadcrumbs :items="breadCombs" class="d-print-none"></v-breadcrumbs>

        <v-divider class="mx-4 d-print-none"></v-divider>
        <v-row class="d-print-none">
            <v-col cols="12" sm="12" md="5">
                <div class="title ml-6" @click="showBaseInfo">{{$vuetify.lang.t('$vuetify.labelDetail.baseInfo')}}</div>
                <v-banner v-model="baseInfo" single-line transition="slide-y-transition">
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.labelDetail.codePrefix')"
                                    v-model="label.prefix"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.labelDetail.code')"
                                    v-model="label.code"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.labelDetail.stockDate')"
                                    v-model="label.stockDate"
                                    readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-banner>
            </v-col>
            <v-divider class="mx-4" vertical></v-divider>

            <v-col cols="12" sm="12" md="6">
                <v-select
                        v-model="label.printSettingId"
                        :items="items"
                        :label="$vuetify.lang.t('$vuetify.printSetting.title')"
                        @change="change"
                ></v-select>
                <v-banner v-model="printSetting" single-line transition="slide-y-transition">
                    <v-row>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.left')"
                                    v-model="label.print.left"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.right')"
                                    v-model="label.print.right"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.top')"
                                    v-model="label.print.top"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.bottom')"
                                    v-model="label.print.bottom"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.qrSize')"
                                    v-model="label.print.qrSize"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.fontSize')"
                                    v-model="label.print.fontSize"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.printSetting.cols')"
                                    v-model="label.print.cols"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-banner>
            </v-col>
        </v-row>

        <v-row class="d-print-none">
            <v-col cols="12" sm="12" md="10">
                <v-btn-toggle>
                    <v-btn small class="indigo white--text" @click.stop="dialog=true">
                        {{$vuetify.lang.t('$vuetify.base.add')}}
                    </v-btn>
                    <v-dialog v-model="dialog" max-width="290">
                        <v-card>
                            <v-card-title class="headline">{{$vuetify.lang.t('$vuetify.base.add')}}</v-card-title>
                            <v-card-actions>
                                <v-text-field
                                        v-model="addWeight"
                                        :label="$vuetify.lang.t('$vuetify.labelDetail.weight')"
                                        :error-messages="errorMessage"
                                        required
                                ></v-text-field>
                            </v-card-actions>
                            <v-btn color="green darken-1" text @click.stop="dialog=false">
                                {{$vuetify.lang.t('$vuetify.base.cancel')}}
                            </v-btn>
                            <v-btn color="green darken-1" text @click.stop="addWeightFunc">
                                {{$vuetify.lang.t('$vuetify.base.confirm')}}
                            </v-btn>
                        </v-card>
                    </v-dialog>
                    <v-btn small class="success white--text" @click.stop="save">
                        {{$vuetify.lang.t('$vuetify.base.save')}}
                    </v-btn>

                </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="12" md="2">
                <v-btn-toggle>
                    <v-btn small v-print="printObj" class="primary white--text"><strong>{{$vuetify.lang.t('$vuetify.printSetting.print')}}</strong>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-dialog v-model="deleteDialog" max-width="290">
            <v-card>
                <v-card-title class="headline">{{$vuetify.lang.t('$vuetify.base.delete')}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false">
                        {{$vuetify.lang.t('$vuetify.base.cancel')}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteWeightConfirm">
                        {{$vuetify.lang.t('$vuetify.base.confirm')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-divider class="d-print-none"/>
        <vuedraggable :list="label.weightInfoList">
            <transition-group class="row">
                <v-col cols="12" sm="6" :md="12/label.print.cols" v-for="(weight, index) in label.weightInfoList"
                       :key="label.id + '_'+index"
                       :style="{paddingTop:label.print.top + 'px',paddingRight:label.print.right + 'px',paddingBottom:label.print.bottom + 'px',paddingLeft:label.print.left + 'px',fontSize: label.print.fontSize + 'pt' }"
                       :class="weight.checked?'':'d-print-none'">
                    <div style="margin-bottom: 5px;">
                        <input type="checkbox" v-model="weight.checked" style="margin-right:2px" class="d-print-none">
                        <span style="font-weight: bold">Bergsoe Metals Co. Ltd.</span>
                        <v-icon small style="margin-left:5px;color:blue" class="d-print-none"
                                @click.stop="deleteWeight(weight.index)">delete
                        </v-icon>
                    </div>
                    <div style="display:flex;flex-direction:row">
                        <div :style="{width:label.print.qrSize + 'px'}" style="position:relative;clear:both">
                            <img src="../assets/logo.png"
                                 :style="{width:label.print.qrSize/5 + 'px',marginTop:label.print.qrSize/10*4 + 'px',marginLeft:label.print.qrSize/10*4 + 'px'}"
                                 style="position:absolute;clear:both"/>
                            <qriously
                                    :value="
										label.productName + ';' +
										label.stockDate + ';' + 
										label.prefix + '-' + 
										label.code + '-' + 
										weight.index + ';' + 
										weight.weight"
                                    :size="label.print.qrSize"
                            />
                        </div>
                        <div style="margin-left:10px">
                            {{label.productName}}<br/>
                            {{label.prefix}}-{{label.code}}-{{weight.index}}<br/>
                            {{label.stockDate}}<br/>
                            <input type="nubmer" v-if='weight.editFlag' v-model="weight.weight"
                                   @blur='weightChange(weight.index)' style="border-radius:10px">
                            <span v-else>{{weight.weight}}kg</span>
                            <v-icon small style="margin-left:5px;color:blue" class="d-print-none"
                                    @click.stop="weightEdit(weight.index)">edit
                            </v-icon>
                        </div>
                    </div>
                </v-col>
            </transition-group>
        </vuedraggable>


    </v-container>

</template>

<script>
    import {mapActions} from 'vuex'

    import vuedraggable from 'vuedraggable';


    export default {
        components: {vuedraggable},
        data: () => ({
            flag: true,
            dialog: false,
            deleteDialog: false,
            deleteIndex: -1,
            addWeight: "",
            baseInfo: true,
            printSetting: true,
            errorMessage: '',

            label: {},
            printObj: {
                id: "printMe",
                popTitle: 'QR',

            },
            items: []
        }),
        computed: {
            breadCombs() {
                return [
                    {
                        text: this.$vuetify.lang.t('$vuetify.base.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: this.$vuetify.lang.t('$vuetify.base.labels'),
                        disabled: false,
                        href: '#/labels',
                    },
                    {
                        text: this.$vuetify.lang.t('$vuetify.base.labelDetail'),
                        disabled: true,
                        href: '#/labelDetail',
                    }
                ]
            },
        },
        created() {
            this.hideDrawer();
            this.getRouterData()
        },
        methods: {
            ...mapActions("navigation", ["hideDrawer"]),
            getRouterData() {
                let id = this.$route.params.id;
                if (id != null && !isNaN(id)) {
                    this.$axios.put(
                        "label?inventoryId=" + id
                    ).then((response) => {
                        var message = response.data.message;
                        if (response.data.code != 200) {
                            this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                            return;
                        } else {
                            this.initData(response.data.data);
                        }
                    }).catch((error) => {
                        console.error(error)
                    });
                } else {
                    this.initData(JSON.parse(localStorage.getItem("labelDetail")));
                }
            },
            initData(label) {
                this.label = label;
                let printSettings = this.label.printSettings;
                let items = [];
                let j = 0
                for (; j < printSettings.length; j++) {
                    let print = printSettings[j];
                    items.push({
                        "text": print.name,
                        "value": print.id
                    });
                }
                this.items = items;
            },
            showBaseInfo() {
                this.baseInfo = !this.baseInfo;
            },
            addWeightFunc() {
                var weightInfoList = this.label.weightInfoList;
                if (weightInfoList == null) {
                    weightInfoList = [];
                }
                if (this.addWeight == null || this.addWeight == "") {
                    this.errorMessage = "weight required";
                    return;
                }
                if (isNaN(this.addWeight)) {
                    this.errorMessage = "not a number";
                    return;
                }
                weightInfoList.push({
                    index: weightInfoList.length + 1,
                    weight: parseFloat(this.addWeight),
                    checked: true
                });
                this.label.weightInfoList = weightInfoList;
                this.dialog = false;
                this.addWeight = "";
            },
            deleteWeight(index) {
                this.deleteIndex = index;
                this.deleteDialog = true;
            },
            deleteWeightConfirm() {
                let index = this.deleteIndex;
                let weightInfoList = this.label.weightInfoList;
                console.info("deleteWeight");
                var newWeithInfoList = [];
                let currentIndex = 0;
                for (; currentIndex < weightInfoList.length; currentIndex++) {
                    if (currentIndex < index - 1) {
                        newWeithInfoList.push(weightInfoList[currentIndex]);
                    } else if (currentIndex >= index) {
                        let weight = weightInfoList[currentIndex];
                        weight.index = currentIndex;
                        newWeithInfoList.push(weight);
                    }
                }
                this.label.weightInfoList = newWeithInfoList;
                this.deleteDialog = false;
                this.deleteIndex = -1;
            },
            weightChange(index) {
                let weightInfoList = this.label.weightInfoList;
                let newWeithInfoList = [];
                let currentIndex = 0;
                for (; currentIndex < weightInfoList.length; currentIndex++) {
                    let weight = weightInfoList[currentIndex]
                    if (index == weight.index) {
                        weight.editFlag = false;
                    }
                    newWeithInfoList.push(weight);
                }
                this.label.weightInfoList = newWeithInfoList;
            },
            weightEdit(index) {
                let weightInfoList = this.label.weightInfoList;
                let newWeithInfoList = [];
                let currentIndex = 0;
                for (; currentIndex < weightInfoList.length; currentIndex++) {
                    let weight = weightInfoList[currentIndex]
                    if (index == weight.index) {
                        weight.editFlag = true;
                    }
                    newWeithInfoList.push(weight);
                }
                this.label.weightInfoList = newWeithInfoList;
            },
            save() {
                this.$axios.post(
                    "label",
                    this.label
                ).then((response) => {
                    if (response.data.code != 200) {
                        const message = response.data.message;
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    let message = 'save label success';
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'info'});
                }).catch((error) => {
                    console.error(error)
                });
            },
            change() {
                let id = this.label.printSettingId;
                let printSettings = this.label.printSettings;
                let j = 0
                for (; j < printSettings.length; j++) {
                    if (id == printSettings[j].id) {
                        this.label.print = JSON.parse(printSettings[j].print);
                    }
                }
            },
            selectFunc(value) {
                console.info(value);
            }
        }

    }
</script>